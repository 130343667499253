html {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.5rem;
}

body {
    margin-top: 20px;
    background: #eee;
}

/* site header */

/* site content */

/* table head desktop */
.table-head {
    /* min-width: 300px; */
    background: #282A35;
    color: #fff;
    min-height: 50px;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    position: relative;
}

.table-head h3 {
    margin: 0;
    display: inline-block;
}

.table-head form {
    display: flex;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    align-items: right;
    justify-content: center;
}

.table-head .sr-input {
    height: 40px;
    border: none;
    box-shadow: none;
    padding: 0 10px;
    float: left;
    color: #8a8a8a;
    border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -ms-border-radius: 4px 0 0 4px;
    -o-border-radius: 4px 0 0 4px;
    min-width: 56px;
    max-width: 120px;
}

.table-head .sr-btn {
    height: 40px;
    border: none;
    background: #059862;
    color: #fff;
    padding: 0 20px;
    border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
}

.table-head a {
    height: 40px;
    float: left;
}

/* paging desktop */
ul.list-pagination {
    float: right;
    list-style: none;
    min-width: 140px;
}

ul.list-pagination li {
    float: left;
}

.list-pagination a.np-btn {
    border: 1px solid #e7e7e7;
    padding: 5px 15px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -ms-border-radius: 3px !important;
    -o-border-radius: 3px !important;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    color: darkred;
}

/* site footer */
.site-footer {
    clear: both;
    background-color: #00152e;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0px;
    width: 100%;
    height:50px
}

.site-footer a,
.site-footer .button:hover {
    color: #fff;
}

.site-footer .site-info {
    padding: 0.01em 16px;
    display: block;
}

.site-footer .copyright {
    font-size: 18px;
    color: #fff;
}

/*  show/hide   */
.hidden-mobile {
    display: none;
}

.hidden-mobile-block {
    display: none;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 767px) {
    /* show/hide */
    .hidden-mobile {
        display: table-cell;
    }

    .hidden-mobile-block {
        display: block;
    }

    .hidden-tablet {
        display: none;
    }

    /* table-head mobile */
    .table-head {
        flex: 12;
    }

    .table-head h3 {
        width: 40%;
    }

    .table-head form {
        display: flex;
        flex-wrap: wrap;
        /* width: 60%; */
    }

    .table-head .sr-input {
        width: 6.5rem;
    }

    .table-head a {
        width: 2.5rem;
    }

    /* paging mobile */
    /* table-rows */
}

/*--------------------- schedule format ----------------*/
.schedule-list {
    max-width: 2293px;
}

.schedule-list tbody tr th {
    width: 50px!important;
    height: 32px;
}

.schedule-list tbody tr th:nth-last-child(-n+2) {
    width: 50px!important;
    height: 32px;
}

.schedule-list tbody tr th:nth-child(1),
.schedule-list tbody tr th:nth-child(1) button {
    width: 100% !important;
    max-width: 350px !important;
    min-width: 200px !important;
    /* word-break: break-word; */
    white-space: nowrap;        /* 1 line */
    overflow: hidden;
    text-overflow: ellipsis;    /*overflow hide and change to ... */
}

/*CSS – One Line Justify*/
.fulljustify-text {
    /* color: black;
    letter-spacing: 0.4vw;
    overflow: hidden; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.schedule-list .teamday-button:hover {
    background-color: tan;
}

/*-------------------- Schedule Shift Type START --------------------*/
.shift-list td.table-danger,
.shift-list-full td.table-danger,
.schedule-list td.table-danger {
    background-color: rgb(99, 3, 3);
}

.shift-list td.table-secondary,
.shift-list-full td.table-secondary,
.schedule-list td.table-secondary {
    background-color: #4d4d4d;
    color: #fcfcfc;
}

.shift-list td.table-dark,
.shift-list-full td.table-dark,
.schedule-list td.table-dark {
    background-color: #000;
    color: #000;
}
.shift-list td.table-secondary,
.shift-list-full td.table-secondary,
.schedule-list td.table-secondary {
    background-color: #aaa;
}

/*start background color custom*/
.bg-celling {
    background-color: #6610f2!important;
}

.bg-roslyn {
    background-color: #99a7ca!important;
}
/*end bg-color*/

/*-------------------- Schedule Shift Type END --------------------*/

.crm-push-notifications {
    display: none;
}

/*table scroll*/
table,tr,th,td {
    border:1px solid #dddddd;
    border-collapse:collapse;
}
.tbl-header {
    width:calc(100% - 1px);
    width:-webkit-calc(100% - 1px);
    width:-moz-calc(100% - 1px);
}

/*------------------------ design schedule ------------------------*/

/*zoom image*/
.schedule-list .bt-zoom {
    --mdb-image-hover-zoom-transition: all 0.3s linear;
    --mdb-image-hover-zoom-transform: scale(1.1);
}
.schedule-list .bg-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.schedule-list .btn-1 {
    transition: 0.2s all;
}
.schedule-list .btn-1:hover,
.schedule-list .btn-1:active,
.schedule-list .btn-1:focus,
.schedule-list .btn-1:visited {
    transform:scale(1.2);
    background-color: #FBEB50;
    border-style: none!important;
    /* background-color: #42A5F5 !important; */
    /* border-style: solid; */
    /* border-color: #fdf5a8 #fdf7b9 #fef9cb #fefbdc !important; */
    /* background-image: none !important; */
    /* border: 0 !important; */
    /* font-weight: 700; */
}

/* .schedule-list tbody tr:hover,
.schedule-list tbody tr:active,
.schedule-list tbody tr:focus,
.schedule-list tbody tr:visited {
    border-bottom: 4px solid rgb(0, 4, 255);
    border-top: 4px solid  rgb(0, 4, 255);
} */

/* ----------- Non-Retina Screens ----------- */
/* @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .schedule-list tbody tr:hover,
    .schedule-list tbody tr:active,
    .schedule-list tbody tr:focus,
    .schedule-list tbody tr:visited {
        border-bottom: 6px solid rgb(0, 4, 255);
        border-top: 6px solid  rgb(0, 4, 255);
    }
} */

/* ----------- Retina Screens ----------- */
/* @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
    .schedule-list tbody tr:hover,
    .schedule-list tbody tr:active,
    .schedule-list tbody tr:focus,
    .schedule-list tbody tr:visited {
        border-bottom: 6px solid rgb(0, 4, 255);
        border-top: 6px solid  rgb(0, 4, 255);
    }
} */

/* ----------- Moto 360 Watch ----------- */
/* @media
  (max-device-width: 218px)
  and (max-device-height: 281px) {
    .schedule-list tbody tr:hover,
    .schedule-list tbody tr:active,
    .schedule-list tbody tr:focus,
    .schedule-list tbody tr:visited {
        border-bottom: 6px solid rgb(0, 4, 255);
        border-top: 6px solid  rgb(0, 4, 255);
    }
} */

/* ----------------------------- BG-Color SAMPLE START ----------------------*/
.bg-blue-diamond {
 background-color: #e6fffb;
}
.bg-blue-skyhigh {
    background-color: #c1f8f5;
}
.bg-blue-late {
    background-color: #aad8d5;
}
.bg-blue-heaven {
    background-color: #d1ecf3;
}
.bg-blue-alice {
    background-color: #96d5de;
}

.bg-blue-wild {
    background-color: #a3d8e8;
}
.bg-blue-streak {
    background-color: #0091b0;
}
.bg-blue-pim {
    background-color: #4ea3bf;
}
.bg-blue-note {
    background-color: #56a9d1;
}
.bg-blue-bird {
    background-color: #1992c1;
}

.bg-blue-king {
    background-color: #06659f;
}
.bg-blue-wedding {
    background-color: #204177;
}
.bg-blue-baby {
    background-color: #d7e2e8;
}

.bg-blue-roslyn {
    background-color: #99a7ca;
}
.bg-blue-mystic {
    background-color: #cae7ef;
}

.bg-blue-celestia {
    background-color: #bddaec;
}
.bg-blue-tranquil {
    background-color: #a2c6de;
}
.bg-blue-lily {
    background-color: #aabfd0;
}
.bg-blue-blue-wind {
    background-color: #0180b3;
}
.bg-blue-celestia2 {
    background-color: #bddaec;
}
/* ----------------------------- BG-Color SAMPLE End ----------------------*/
/*-- select2 --*/
.select2-container .select2-selection--single {
    height: 38px!important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px!important;
}
.select2-container .selection {
    text-align: left!important;
}



/* -----------------------------  Table fixed header and scrollable body ----------------------------- */
/* .schedule-list button[disabled="disabled"] {
    background: red;
} */
.nav-link {
    cursor: pointer;
}